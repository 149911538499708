<template>
  <div class="container-fluid">
   
    <v-row>
        <v-col>
            <v-card class="d-flex align-items-center m-1">
              <v-card-text>
                <v-card-title>Gerenciamento de pedidos Xamego Bom</v-card-title>
                <!-- <v-alert type="info" border="top" dense dismissible v-if="usuario.username != 'marcos'">
                    <ul>
                        <li>Aqui é possível consultar vários indicadores da sua representação, como faturamentos mensais, anuais, novos clientes, etc. </li>
                        <li>Na aba "Produtos" são disponibilizados comparativos e históricos mês a mês com visão voltada para os produtos vendidos</li>
                    </ul>
                    <hr> 
                    <small>Utilize os menus acima para navegar entre as opções</small>
                </v-alert> -->
              </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <v-dialog
      v-model="busy"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Carregando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <template>
      <v-tabs>

        <v-tab>Comercial</v-tab>
          <v-tab-item>
            <v-row dense class="text-center justify-content-center">
              <v-card>
                <v-card-text>
                <v-card-title>Relatórios em PDF</v-card-title>
                  <v-btn small outlined color="primary" class="m-1" :loading="loadingRelatorio" @click="obterRelatorioTitulos()">Títulos Abertos (FRCR139)</v-btn>
                  <v-btn small outlined color="primary" class="m-1" :loading="loadingRelatorio" @click="obterRelatorioCarga()">Cargas - Remessa (RFNF210)</v-btn>
                  <v-btn small outlined color="primary" class="m-1" :loading="loadingRelatorio" @click="obterRelatorioAcompanhamento()">Acompanhamento de Vendas (DSGE302)</v-btn>
                </v-card-text>
              </v-card>
            </v-row>

            <v-row dense>
              <!-- TOTAL FATURADO NO MES -->
              <v-col>
                <v-card height="100%" class="d-flex align-items-center m-1" :loading="loadingCards">
                  <v-card-text class="text-center">
                    <div v-if="faturado.faturado.length > 0">
                      <v-progress-circular
                        :rotate="-90"
                        :size="125"
                        :width="15"
                        :value="previsto === undefined ? 0 : Number.parseFloat((faturado.total/previsto.pesliq)*100)"
                        color="teal"
                      >
                        {{ Number.parseFloat(faturado.total).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}) }}
                        <br>
                        {{previsto === undefined ? 0 : Number.parseFloat((faturado.total/previsto.pesliq)*100).toFixed(2)}}%
                      </v-progress-circular>
                    </div>
                    <h4>Faturamento Geral do Mês (KG)</h4>
                    <div class="text-center">
                      <small>Não inclui amostras,trocas e bonificações</small>
                    </div>
                    <hr>
                    <p><strong>Peso (KG):</strong> {{faturado.faturado.length > 0 ? Number.parseFloat(faturado.faturado[0].Peso).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}) : 0}}
                    | <strong>Devolvido (KG):</strong> {{faturado.devolvido.length > 0 ?  Number.parseFloat(faturado.devolvido[0].xpliq).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}): 0}}</p>
                  </v-card-text>
                </v-card>

              </v-col>

              <!-- CLIENTES NOVOS NO MÊS -->
              <v-col cols="12" sm="4">
                <NovosClientes :token="token" />
              </v-col>

              <!-- CLIENTES REATIVADOS NO MÊS -->
              <v-col>
                <ClientesReativados :token="token" />
              </v-col>

            </v-row>

            <v-row dense>
              <!-- TOTAL VENDIDO NO MÊS -->
              <v-col>
                <v-card height="100%" class="d-flex align-items-center m-1" :loading="loadingCards">
                  <v-card-text class="text-center">
                    <div v-if="faturado.faturado.length > 0">
                      <v-progress-circular
                        :rotate="-90"
                        :size="125"
                        :width="15"
                        :value="objetivoAtingido"
                        color="blue-grey"
                      >
                        {{vendidoMes.length > 0 ? Number.parseFloat(pesoVendido).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}) : 0}}
                        <br>
                        {{Number.parseFloat(objetivoAtingido).toFixed(2)}}%
                      </v-progress-circular>
                    </div>
                    <h4>Faturado Mês Atual (KG)</h4>
                    <hr>
                    <small>Não inclui amostras,trocas e bonificações e contempla apenas pedidos colocados dentro do mês</small>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- PEDIDOS PENDENTES DO MES-->
              <v-col>
                <v-card height="100%" class="d-flex align-items-center m-1">
                  <v-card-text class="text-center">
                    <h2>{{pesoPendentesMes}}</h2>
                    <h4>Total Peso (KG Liq.) Pedidos Pendentes mês atual</h4>
                    <small> Pedidos com situação "Aberto Total" ou "Aberto Parcial" colocados dentro do mês. </small>
                    <hr>  
                    <v-badge color="red" overlap :content="pedidosPendentesMes ? pedidosPendentesMes.length : 0">  
                      <v-btn color="primary" @click="detalhes.pendentesMes = true" small>Detalhar
                        <v-icon dense class="m-1">mdi-list-status</v-icon>
                      </v-btn>
                    </v-badge>
                  </v-card-text>
                </v-card>
              </v-col>
            
              <!-- PEDIDOS PENDENTES-->
              <v-col>
                <v-card height="100%" class="d-flex align-items-center m-1">
                  <v-card-text class="text-center">
                    <h2>{{pesoPendentes}}</h2>
                    <h4>Total Peso (KG Liq.) Pedidos Pendentes de Faturamento</h4>
                    <small> Pedidos com situação "Aberto Total" ou "Aberto Parcial".</small>
                    <hr>
                        <v-badge color="red" overlap :content="pedidosPendentes ? pedidosPendentes.length : 0">  
                        <v-btn color="primary" @click="detalhes.pendentesFaturamento = true" small>Detalhar
                          <v-icon dense class="m-1">mdi-list-status</v-icon>
                        </v-btn>
                    </v-badge>
                  </v-card-text>

                </v-card>
              </v-col>

            </v-row>
            
            <v-row dense>
              <v-col cols="12" sm="4">
                <!-- PREVISÃO MES -->
                <v-card height="100%" class="d-flex align-items-center m-1">
                  <v-card-text class="text-center">
                    <h2>{{previsto ? Number.parseFloat(previsto.pesliq).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}) : 0}}</h2>
                    <h4 >Previsão do mês (KG)</h4>
                    <v-divider></v-divider>  
                    <v-progress-circular
                      :rotate="-90"
                      :size="125"
                      :width="15"
                      :value="objetivoAtingido"
                      color="deep-orange"
                    >
                      {{Number.parseFloat(objetivoAtingido).toFixed(2)}}%
                    </v-progress-circular>

                    <!-- <h2>{{Number.parseFloat(objetivoAtingido).toLocaleString('pt-br',{minimumFractionDigits:2,maximumFractionDigits:2})}}</h2> -->
                    <h4 class="text-center"> % Previsão Atingida (KG)</h4>
                    <hr>
                    <small><strong>Cálculo: </strong> (Faturado Mês Atual - Devolução Faturamento Geral Mês) / Previsão Mês </small>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="8">
                
                <!-- MES A MES -->
                <v-card height="100%" class="d-flex align-items-center m-1" :loading="loadingCards">
                    <v-card-text class="text-center" >
                        <h4>{{`Histórico Mês a Mês ${new Date().getFullYear()-1} x ${new Date().getFullYear()}`}} (KG)</h4>
                        <small>Abate Devoluções e NÃO inclui trocas amostras e bonificações</small>
                        <LineChartX v-if="faturado.historico.length > 0" :chart-data="chartHistory" :chart-options="chartOptions"/>
                    </v-card-text>
                </v-card>

              </v-col>

            </v-row>

            <v-row dense style="page-break-inside: avoid;"> 
              <v-col cols="12" sm="4">

                <!-- FATURADO 12 MESES -->
                <v-card height="100%" class="d-flex align-items-center m-1">
                  <v-card-text class="text-center">
                    <div v-if="faturado.anual.length > 0">
                    <h2>{{faturado.anualdevolvido.length > 0 ? Number.parseFloat(faturado.anual[0].Peso -  faturado.anualdevolvido[0].xpliq).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0}) : 0}}</h2>
                    <h4>Total Faturado Últimos 12 Meses(KG)</h4>
                    </div>
                    <v-divider></v-divider>  
                    <small> Não inclui trocas, amostras e bonificações. Não inclui mês atual. </small>
                  </v-card-text>
                </v-card>

              </v-col>
              <v-col cols="12" sm="8">

                <!-- MES A MES ACUMULADO -->
                <v-card height="100%" class="d-flex align-items-center m-1" :loading="loadingCards">
                    <v-card-text class="text-center" >
                        <h4>{{`Histórico Mês a Mês ${new Date().getFullYear()-1} x ${new Date().getFullYear()}`}} Acumulado (KG)</h4>
                        <small>Abate Devoluções e NÃO inclui trocas amostras e bonificações</small>
                        <LineChartX v-if="faturado.historico.length > 0" :chart-data="chartHistoryAcumulado" :chart-options="chartOptions"/>
                    </v-card-text>
                </v-card>

              </v-col>
            </v-row>



            <!-- TOP 20 -->
            <v-row dense style="page-break-inside: avoid;">
              <v-col cols="12">
                <v-card class="d-flex align-items-center m-1 p-1">
                  <v-card-text class="text-center">
                    <h4>TOP 20 Maiores Clientes/Grupos</h4>
                    <hr>
                    <small>Não inclui amostras,trocas e bonificações e contempla os últimos 12 meses. Não inclui mês atual.</small>
                    <hr>
                    <v-data-table
                      :loading="maioresClientesFull.length == 0"
                      dense
                      :headers="[{text:'Código Cliente/Grupo',value:'codcli'},{text:'Razão Social',value:'nomcli'},{text:'Peso Líquido (KG)',value:'pesliq',align:'right'},{text:'% Representatividade',value:'rep'},{text:'Ação', value:'acao'}]" 
                      :items="maioresClientesFull">
                      <template v-slot:[`item.acao`]="{item}">
                        <span>
                          <v-btn :disabled="item.codgre > 0" color="primary" small @click="obterDetalheCliente(item.codcli)">ver mais</v-btn>
                        </span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>


          </v-tab-item>

        <v-tab>Produtos</v-tab>
          <v-tab-item >
            <GraficosProdutos :token="token" />
          </v-tab-item>

        <v-tab>Representantes</v-tab>
          <v-tab-item>
            
            <GraficosRepresentantes :token="token" v-if="usuario.role == 'master'" />

            <!-- PEDIDOS DIA A DIA -->
            <v-row dense style="page-break-inside: avoid;">
              <v-col cols="12">
                <PedidosDiaDia :token="token" />
              </v-col>
              <v-col cols="12">
                <FaturadosDiaDia :token="token" />
              </v-col>
            </v-row>

            <!-- FATURADO DIA A DIA -->
            <v-row dense style="page-break-inside: avoid;">
            </v-row>
            
          </v-tab-item>

        <v-tab>Regiões</v-tab>
          <v-tab-item>
            <Regioes :token="this.token" />
          </v-tab-item>

        <v-tab v-if="usuario.role == 'master'">Pedidos Autorizados</v-tab>
          <v-tab-item><PrePedidosMarcos /></v-tab-item>
        <!-- SESSÃO GERENCIAL -->
          <v-spacer></v-spacer>
        

        <v-tab v-if="usuario.role == 'master'"> GERENCIAL</v-tab>
        <v-tab-item v-if="usuario.role == 'master'">
          <Gerencial :token="this.token" />
          <Regioes :token="this.token" />
          <!-- <h2>SESSÃO GERENCIAL COMEÇA AQUI</h2> -->
        </v-tab-item>
        
        <v-tab v-if="usuario.role == 'master'">SAC</v-tab>
          <v-tab-item v-if="usuario.role == 'master'">
            
            <v-row dense>
              <!-- Números SAC -->
                  <v-col>
                      <v-card height="100%" class="d-flex align-items-center m-1">
                        <v-card-text class="text-center" v-if="relatoriosSAC.protocolos">
                          <h2>{{relatoriosSAC.protocolos.length}}</h2>
                          <h4>Protocolos em aberto</h4>
                          <small> Protocolos abertos no sistema SAC que ainda não foram finalizados </small>
                          <hr>
                          <v-btn color="primary" @click="detalhes.protocolosAbertos = true" small>Detalhar
                            <v-icon dense class="m-1">mdi-list-status</v-icon>
                          </v-btn>
                        </v-card-text>
                      </v-card>
                  </v-col>

                  <v-col>

                      <v-card height="100%" class="d-flex align-items-center m-1">
                        <v-card-text class="text-center" v-if="relatoriosSAC.todos">
                          <h2>{{relatoriosSAC.todos.length}}</h2>
                          <h4>Protocolos Abertos no Mês</h4>
                          <hr>
                          <h2>{{Number.parseFloat(mediaProtocoloAberto).toFixed(2)}}</h2>
                          <h4>Tempo médio aberto (dias)</h4>
                          <small> Protocolos abertos no sistema SAC dentro do mês vigente </small>
                          <hr>
                          <v-btn color="primary" @click="detalhes.protocolosMes = true" small>Detalhar
                            <v-icon dense class="m-1">mdi-list-status</v-icon>
                          </v-btn>
                        </v-card-text>
                        
                        <hr>
                      </v-card>
                  </v-col>

                  <v-col v-if="ocorrenciasDistintas">
                    <v-card height="100%" class="d-flex align-items-center m-1">
                      <v-card-text class="text-center">
                        <h4>Protocolos Registrados no Mês por Tipo (Ocorrências de Produtos)</h4>
                        <table class="table table-sm">
                          <tr>
                            <th>Tipo</th>
                            <th>Quantidade</th>
                          </tr>
                          <tr v-for="(ocorrencia,index) in ocorrenciasDistintas.tipos" :key="index">
                            <td>{{ocorrencia.tipo}}</td>
                            <td>{{ocorrencia.qtd}}</td>
                          </tr>
                        </table>
                      </v-card-text>
                    </v-card>
                  </v-col>
            </v-row>

            <v-row dense>
                  <v-col v-if="ocorrenciasDistintas">                        
                    <v-card height="100%" class="d-flex align-items-center m-1">
                          <v-card-text class="text-center">
                      <v-row>
                        <v-col>

                            <h4>Quantidade Protocolos Por Complemento</h4>
                            <table class="table table-sm">
                              <tr>
                                <th>Ocorrência</th>
                                <th>Quantidade</th>
                              </tr>
                              <tr v-for="(ocorrencia,index) in ocorrenciasDistintas.complementos.sort((a,b) => b.qtd - a.qtd)" :key="index">
                                <td>{{ocorrencia.complemento}}</td>
                                <td>{{ocorrencia.qtd}}</td>
                              </tr>
                            </table>
                          
                        </v-col>
                        <v-col>
                          <DoughnutChartX v-if="ocorrenciasDistintas.complementos.length > 0" 
                            :chart-data="{labels:ocorrenciasDistintas.complementos.map(c => c.complemento),
                                        datasets:[{data:ocorrenciasDistintas.complementos.map(c => c.qtd),
                                        backgroundColor: ocorrenciasDistintas.complementos.map(() => `#${Math.floor(Math.random()*16777215).toString(16)}`)}]}" 
                                        :chart-options="chartOptions"/>
                        </v-col>
                      </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
            </v-row>
          </v-tab-item>

        

      </v-tabs>
    </template>

    <!-- MODAIS -->
    <div>
      <!-- DIALOG PENDENTE DO MES -->
      <v-dialog v-model="detalhes.pendentesMes">
        <v-card>
          <v-btn fab x-small color="secondary" class="m-1" dark @click="detalhes.pendentesMes = false"><v-icon>mdi-close</v-icon></v-btn>
          <v-card-title>Pedidos pendentes do mês</v-card-title>
          <v-card-text>
            <div v-if="pedidosPendentesMes.length > 0">
              <v-data-table :headers="[
                  {text:'Pedido',value:'numped'},
                  {text:'Emissão',value:'datemi'},
                  {text:'Tipo',value:'tnspro'},
                  {text:'Cód. Cliente',value:'codcli'},
                  {text:'Razão Social',value:'nomcli'},
                  {text:'UF',value:'sigufs'},
                  {text:'Representante',value:'nomrep'},
                  {text:'KG Pendente',value:'pesliq'},
                  {text:'Agendamento',value:'datage'}
                ]" :items="pedidosPendentesMes">
                <template v-slot:[`item.datemi`]="{ item }">
                  <span>
                    {{new Date(String(item.datemi).split('T')[0].split('-')[0],String(item.datemi).split('T')[0].split('-')[1] -1 ,String(item.datemi).split('T')[0].split('-')[2]).toLocaleDateString('pt-br')}}
                  </span>
                </template>

                <template v-slot:[`item.tnspro`]="{ item }">
                  <span>
                    {{item.tnspro == '90100' ? 'Venda' :
                      item.tnspro == '90110' ? 'Bonificação' : 
                      item.tnspro == '90111' ? 'Troca' : 
                      item.tnspro == '90112' ? 'Amostra' : 'Outro'}}
                  </span>
                </template>

                <template v-slot:[`item.pesliq`]="{ item }">
                  <span>
                  {{Number.parseFloat(item.pesliq).toLocaleString('pt-br',{minimumFractionDigits: 2,maximumFractionDigits:2})}}
                  </span>
                </template>

                <template v-slot:[`item.datage`]="{ item }">
                  <span>
                    {{new Date(item.datage).getFullYear() == 1900 ? '' : new Date(item.datage.replace(/-/g,'\/').replace(/T.+/,'')).toLocaleDateString('pt-br')}}                
                  </span>
                </template>
              </v-data-table>

            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- DIALOG PENDENTE DE FATURAMENTO -->
      <v-dialog v-model="detalhes.pendentesFaturamento">
        <v-card>
          <v-btn fab x-small color="secondary" class="m-1" dark @click="detalhes.pendentesFaturamento = false"><v-icon>mdi-close</v-icon></v-btn>
          <v-card-title>Pedidos não faturados</v-card-title>
          <v-card-text>
            <div v-if="pedidosPendentes.length > 0">
              <v-data-table
                :headers="[
                  {text:'Pedido',value:'numped'},
                  {text:'Emissão',value:'datemi'},
                  {text:'Tipo',value:'tnspro'},
                  {text:'Cód. Cliente',value:'codcli'},
                  {text:'Razão Social',value:'nomcli'},
                  {text:'UF',value:'sigufs'},
                  {text:'Representante',value:'nomrep'},
                  {text:'KG Pendente',value:'pesliq'},
                  {text:'Agendamento',value:'datage'}
                ]"
                :items="pedidosPendentes"  
              >
              <template v-slot:[`item.datemi`]="{ item }">
                <span>
                  {{new Date(String(item.datemi).split('T')[0].split('-')[0],String(item.datemi).split('T')[0].split('-')[1] -1 ,String(item.datemi).split('T')[0].split('-')[2]).toLocaleDateString('pt-br')}}
                </span>
              </template>

              <template v-slot:[`item.tnspro`]="{ item }">
                <span>
                  {{item.tnspro == '90100' ? 'Venda' :
                    item.tnspro == '90110' ? 'Bonificação' : 
                    item.tnspro == '90111' ? 'Troca' : 
                    item.tnspro == '90112' ? 'Amostra' : 'Outro'}}
                </span>
              </template>

              <template v-slot:[`item.pesliq`]="{ item }">
                <span>
                 {{Number.parseFloat(item.pesliq).toLocaleString('pt-br',{minimumFractionDigits: 2,maximumFractionDigits:2})}}
                </span>
              </template>

              <template v-slot:[`item.datage`]="{ item }">
                <span>
                  {{new Date(item.datage).getFullYear() == 1900 ? '' : new Date(item.datage.replace(/-/g,'\/').replace(/T.+/,'')).toLocaleDateString('pt-br')}}                
                </span>
              </template>

              </v-data-table>
              
              <!-- <table class="table table-sm table-responsive">
                <tr>
                  <th>Pedido</th>
                  <th>Emissão</th>
                  <th>Tipo</th>
                  <th>Cód. Cliente</th>
                  <th>Razão Social</th>
                  <th>Peso Líquido (Pendente)</th>
                  <th>Data Previsão Agendamento</th>
                </tr>
                <tr v-for="pedido in pedidosPendentes" :key="pedido.numped" class="text-right">
                  <td>{{pedido.numped}}</td>
                  <td>{{new Date(String(pedido.datemi).split('T')[0].split('-')[0],String(pedido.datemi).split('T')[0].split('-')[1] -1 ,String(pedido.datemi).split('T')[0].split('-')[2]).toLocaleDateString('pt-br')}}</td>
                  <td>{{pedido.tnspro == '90100' ? 'Venda' : pedido.tnspro == '90110' ? 'Bonificação' : 
                          pedido.tnspro == '90111' ? 'Troca' : pedido.tnspro == '90112' ? 'Amostra' : 'Outro'}}</td>
                  <td>{{pedido.codcli}}</td>
                  <td>{{pedido.nomcli}}</td>
                  <td>{{Number.parseFloat(pedido.pesliq).toLocaleString('pt-br',{minimumFractionDigits: 2,maximumFractionDigits:2})}}</td>
                  <td>{{new Date(pedido.datage).getFullYear() == 1900 ? '' : new Date(pedido.datage.replace(/-/g,'\/').replace(/T.+/,'')).toLocaleDateString('pt-br')}}</td>

                </tr>
              </table> -->


            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- DIALOG PROTOCOLOS EM ABERTO -->
      
      <v-dialog v-model="detalhes.protocolosAbertos" v-if="usuario.role == 'master'">
        <v-card>
          <v-btn fab x-small color="secondary" class="m-1" dark @click="detalhes.protocolosAbertos = false"><v-icon>mdi-close</v-icon></v-btn>
          <v-card-title>Protocolos Abertos</v-card-title>
          <v-card-text>
            <div v-if="usuario.role == 'master' && relatoriosSAC.protocolos.length > 0">
                <v-card v-for="protocolo in relatoriosSAC.protocolos" :key="protocolo.id" style="white-space: pre-wrap" class="m-2">
                  <v-card-text>
                    <p><strong>Protocolo: </strong> {{protocolo.NumProtocolo}}</p>
                    <p><strong>Reclamante: </strong> {{protocolo.NomeReclamante}}</p>
                    <p><strong>Data Abertura: </strong> {{new Date(String(protocolo.DataAbertura).split('T')[0].split('-')[0],String(protocolo.DataAbertura).split('T')[0].split('-')[1] -1 ,String(protocolo.DataAbertura).split('T')[0].split('-')[2]).toLocaleDateString('pt-br')}}</p>
                    <p><strong>Tempo em Aberto: </strong> {{((new Date() - new Date(String(protocolo.DataAbertura).split('T')[0].split('-')[0],String(protocolo.DataAbertura).split('T')[0].split('-')[1] -1 ,String(protocolo.DataAbertura).split('T')[0].split('-')[2]))/(1000*60*60*24)).toFixed(0)}} Dias</p>
                    <p><strong>Origem: </strong> {{protocolo.Origem}}</p>
                    <p><strong>Observação: </strong> {{protocolo.Observacao}}</p>
                    <p><strong>Ação: </strong> {{protocolo.Acao}}</p>
                  </v-card-text>
                </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- DIALOG PROTOCOLOS DO MES -->
      <v-dialog v-model="detalhes.protocolosMes" v-if="usuario.role == 'master'">
        <v-card>
          <v-btn fab x-small color="secondary" class="m-1" dark @click="detalhes.protocolosMes = false"><v-icon>mdi-close</v-icon></v-btn>
          <v-card-title>Protocolos do Mês</v-card-title>
          <v-card-text>
            <div v-if="usuario.role == 'master' && relatoriosSAC.todos.length > 0">
                <v-card v-for="protocolo in relatoriosSAC.todos" :key="protocolo.id" style="white-space: pre-wrap" class="m-2">
                  <v-card-text>
                    <p><strong>Protocolo: </strong> {{protocolo.NumProtocolo}}</p>
                    <p :style="!protocolo.Finalizado ? 'color:#f00;font-size:1.3rem': ''"><strong>Status: </strong> {{protocolo.Finalizado=='S'? 'Finalizado' : 'Aberto'}}</p>
                    <p><strong>Reclamante: </strong> {{protocolo.NomeReclamante}}</p>
                    <p><strong>Data Abertura: </strong> {{new Date(String(protocolo.DataAbertura).split('T')[0].split('-')[0],String(protocolo.DataAbertura).split('T')[0].split('-')[1] -1 ,String(protocolo.DataAbertura).split('T')[0].split('-')[2]).toLocaleDateString('pt-br')}}</p>
                    <p><strong>Data Fechamento: </strong> {{new Date(String(protocolo.DataFechamento).split('T')[0].split('-')[0],String(protocolo.DataFechamento).split('T')[0].split('-')[1] -1 ,String(protocolo.DataFechamento).split('T')[0].split('-')[2]).toLocaleDateString('pt-br')}}</p>
                    <p><strong>Tempo em Aberto: </strong> {{protocolo.Finalizado == 'S' ? protocoloTempoAberto(protocolo).diferencaFechamento : protocoloTempoAberto(protocolo).diferencaHoje}} Dias</p>
                    <p><strong>Origem: </strong> {{protocolo.Origem}}</p>
                    <p><strong>Observação: </strong> {{protocolo.Observacao}}</p>
                    <p><strong>Ação: </strong> {{protocolo.Acao}}</p>
                  </v-card-text>
                </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogVerMaisCliente">
        <v-card>
          <v-card-text>
            <Detalhes v-if="clienteProp" :clienteProp="clienteProp" :key="clienteProp.cnpj"/>
          </v-card-text>
        </v-card>
      </v-dialog> 

      <!-- <v-dialog v-model="detalhes.atualizarCadastroRepresentante">
        <AtualizacaoCadastro :token="token" v-on:fecharAtualizacaoRepresentante="fecharAtualizacaoRepresentante" />
      </v-dialog> -->

    </div>

  </div>
</template>

<script>
// import LineChart from '@/components/charts/LineCharts'
// import BarChart from '@/components/charts/BarChart'
import GraficosProdutos from '@/components/relatorios/GraficosProdutos.vue'
import GraficosRepresentantes from '@/components/relatorios/GraficosRepresentantes.vue'
import NovosClientes from '@/components/relatorios/NovosClientes.vue'
import ClientesReativados from '@/components/relatorios/ClientesReativados.vue'
import PedidosDiaDia from '@/components/relatorios/PedidosDiaDia.vue'
import FaturadosDiaDia from '@/components/relatorios/FaturadosDiaDia.vue'
import Regioes from '@/components/relatorios/RegioesComponent.vue';
import Gerencial from '@/components/relatorios/GerencialComponent.vue';

import PrePedidosMarcos from '@/components/pedidos/ConsultarPrePedidosMarcos.vue';

// import AtualizacaoCadastro from '@/components/clientes/AtualizacaoCadastro.vue';

import { Line as LineChartX, Doughnut as DoughnutChartX } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale,LineElement, PointElement,ArcElement,Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale,PointElement,ArcElement,Filler);

export default {
  components:{
    // LineChart,
    // BarChart,
    LineChartX,
    DoughnutChartX,
    GraficosProdutos,
    GraficosRepresentantes,
    NovosClientes,
    ClientesReativados,
    PedidosDiaDia,
    FaturadosDiaDia,
    Regioes,
    Gerencial,
    // AtualizacaoCadastro,
    PrePedidosMarcos,
    Detalhes: () => import('./clientes/DetalhesClientesGeral.vue')
  },

  data(){
    return{
      clienteProp:{},
      dialogVerMaisCliente:false,

      meses:['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'],
      chartHistory:{},
      chartHistoryAcumulado:{},
      chartOptions:{
        responsive: true, 
        maintainAspectRatio: false,
        showAllTooltips: true,
        legend:{
            display:true,
            position:'bottom'
        },
      },
      busy: true,
      loadingReativados:false,
      loadingCards: true,
      token: '',
      detalhes:{
        pendentesFaturamento: false,
        pendentesMes: false,
        protocolosAbertos: false,
        protocolosMes: false,
        atualizarCadastroRepresentante: false,
      },
      faturado: {
        faturado: [],
        devolvido: [],
        maioresClientes: [],
        maioresGrupos: [],
        anual: [],
        anualdevolvido: [],
        historico: [],
      },
      devolvido: null,
      previsto: {},
      vendidoMes: [],
      pedidosPendentes: [],
      pedidosPendentesMes: [],
      conteudoModal:[],
      relatoriosSAC:{
        protocolos:[],
        todos:[],
      },
      usuario:{
        role:''
      },
      loadingRelatorio:false,
    }
  },
  beforeMount(){
    this.$nextTick(function(){
      this.token = sessionStorage.getItem('token');
      if(this.token){
        let dec = JSON.parse(atob(this.token.split('.')[1]));
        this.usuario = dec;
        const promises = [
            this.obterPedidosPendentes(),
            this.obterVendido(),
            this.obterPrevisto(),
            this.obterFaturado(),
        ]
        
        Promise.all(promises)

        if(this.usuario.role == 'master'){
          this.obterRelatoriosSac();
        }
      }else{
        this.$router.push('/login');
      }
    })
  },
  mounted(){

  },

  computed:{

    pesoPendentes(){
      let pendente = this.pedidosPendentes.length > 0 ? this.pedidosPendentes.reduce((acc,ped) => acc + ped.pesliq, 0) : 0;
      return Number.parseFloat(pendente).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0});
    },

    pesoPendentesMes(){
      let pendente = this.pedidosPendentesMes.length > 0 ? this.pedidosPendentesMes.reduce((acc,ped) => acc + ped.pesliq, 0) : 0;
      return Number.parseFloat(pendente).toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0});
    },

    pesoVendido(){
      let vendido = this.vendidoMes.length > 0 ? this.vendidoMes.reduce((acc,ped) => acc + ped.pesliq, 0) : 0;
      return Number.parseFloat(vendido);
    },

    objetivoAtingido(){
      let atingido = 0;
      
      if(this.previsto){
        if(this.faturado.devolvido.length > 0){
          atingido = ((this.pesoVendido - this.faturado.devolvido[0].xpliq) / this.previsto.pesliq) * 100;
        }else{
          atingido = ((this.pesoVendido - 0) / this.previsto.pesliq) * 100;
        }
      }
      
      return atingido;
    },

    mediaProtocoloAberto(){
      if(this.relatoriosSAC.todos){

        let total = 0;
        let x = this.relatoriosSAC.todos.map(p => {
          let diff = this.protocoloTempoAberto(p)
          p.dias = p.Finalizado == 'S' ? diff.diferencaFechamento : diff.diferencaHoje;
          total += Number.parseInt(p.dias);
          return p
        })
        let qtd = x.length
        return total/qtd;
      }else{
        return null;
      }
    },

    ocorrenciasDistintas(){
      if(this.relatoriosSAC.ocorrencias){

        let ocorrencias = this.relatoriosSAC.ocorrencias.map(o => o.TipoOcorrencia);
        let complementos = this.relatoriosSAC.ocorrencias.map(o => o.Complemento);
        return {
          tipos:[... new Set(ocorrencias)].map(o => {
            let qtd = ocorrencias.filter(x => x == o).length;
            return {
              tipo: o,
              qtd: qtd
            }
          }),
          complementos: [... new Set(complementos)].map(c => {
            let qtd = complementos.filter(x => x == c).length;
            return{
              complemento:c,
              qtd: qtd
            }
          })
        }
      }else{
        return null;
      }
    },

    maioresClientesFull(){
      let lista = JSON.parse(JSON.stringify([... this.faturado.maioresClientes,... this.faturado.maioresGrupos]))
      lista.map(l => {
        let faturadoAnual = this.faturado.anual.length > 0 ? this.faturado.anual[0].Peso : 0;
        let devolvidoAnual = this.faturado.anualdevolvido.length > 0 ? this.faturado.anualdevolvido[0].xpliq : 0;

        l.codcli = l.codgre ? l.codgre : l.codcli;
        l.nomcli = l.nomgre ? `(GRUPO) ${l.nomgre}`: l.nomcli;
        l.devolucao = l.devolucao ? l.devolucao : 0;
        l.pesliq = Number.parseFloat(l.Peso - l.devolucao);

        l.rep = `${Number.parseFloat((Number.parseFloat(l.pesliq / (faturadoAnual - devolvidoAnual)))*100).toFixed(2)} %`;
        
        return l
      })
      lista.sort((a,b) => b.pesliq - a.pesliq);
      lista.map(l => {
        l.pesliq = l.pesliq.toLocaleString('pt-br',{minimumFractionDigits:0,maximumFractionDigits:0});
        return l
      })
      return lista.slice(0,20)
    },

  },

  methods:{
    obterDetalheCliente(codcli){
      this.$axios.get(`/clientes/codigo/${codcli}`,{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp => {
        const cliente = resp.data
        this.clienteProp = cliente;
        this.dialogVerMaisCliente = true;
      }).catch(err => {
        console.log(err);
        alert('Não foi possível obter os dados do cliente')
      })

    },
    obterRelatorioTitulos(){
      this.loadingRelatorio = true
      this.$axios.get('/relatorios/titulos-abertos/', {
          responseType: 'blob',headers:{'Authorization':`Bearer ${this.token}`}
      })
      .then(response => {
        this.loadingRelatorio = false;
          const file = new Blob([response.data],{ type: "application/pdf" });
          const fileURL = window.URL.createObjectURL(file);
          
          window.open(fileURL);
      })
      .catch(error => {
        this.loadingRelatorio = false;
          if(error.response.status == 404){
            alert('Nada Encontrado')
          }
          console.log(error);
      });
    },

    obterRelatorioCarga(){
      this.loadingRelatorio = true
      this.$axios.get('/relatorios/cargas/', {
          responseType: 'blob',headers:{'Authorization':`Bearer ${this.token}`}
      })
      .then(response => {
        this.loadingRelatorio = false;
          const file = new Blob([response.data],{ type: "application/pdf" });
          const fileURL = window.URL.createObjectURL(file);
          
          window.open(fileURL);
      })
      .catch(error => {
        this.loadingRelatorio = false;
          if(error.response.status == 404){
            alert('Nada Encontrado')
          }
          console.log(error);
      });
    },
    obterRelatorioAcompanhamento(){
      this.loadingRelatorio = true
      this.$axios.get('/relatorios/acompanhamento/', {
          responseType: 'blob',headers:{'Authorization':`Bearer ${this.token}`}
      })
      .then(response => {
        this.loadingRelatorio = false;
          const file = new Blob([response.data],{ type: "application/pdf" });
          const fileURL = window.URL.createObjectURL(file);
          
          window.open(fileURL);
      })
      .catch(error => {
        this.loadingRelatorio = false;
          if(error.response.status == 404){
            alert('Nada Encontrado')
          }
          console.log(error);
      });
    },

    protocoloTempoAberto(protocolo){
      let aberturaSplit = String(protocolo.DataAbertura).split('T')[0].split('-');
      let fechamentoSplit = String(protocolo.DataFechamento).split('T')[0].split('-');
      let diferencaHoje = ((new Date() - new Date(aberturaSplit[0],aberturaSplit[1] -1 ,aberturaSplit[2]))/(1000*60*60*24)).toFixed(0);
      let diferencaFechamento = ((new Date(fechamentoSplit[0],fechamentoSplit[1] -1, fechamentoSplit[2]) - new Date(aberturaSplit[0],aberturaSplit[1] -1 ,aberturaSplit[2]))/(1000*60*60*24)).toFixed(0);

      return{
        diferencaHoje: isNaN(diferencaHoje) ? 0 : diferencaHoje,
        diferencaFechamento: isNaN(diferencaFechamento) ? 0 : diferencaFechamento
      }
    },


    obterFaturado(){
      this.$axios.get('/relatorios/faturado',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp =>{
        let anoAtual = new Date().getFullYear();
        let anoAnterior = anoAtual -1;

        this.faturado = resp.data;
        
        //GRAFICO HISTORICO ANUAL
        let dadoh1 = this.faturado.historico.filter(h => h.ano == anoAtual);
        let devolvidoh1 = this.faturado.historicoDevolucao.filter(h => h.ano == anoAtual);

        //GRAFICO HISTORICO Ano Passado
        let dadoh2 = this.faturado.historico.filter(h => h.ano == anoAnterior);
        let devolvidoh2 = this.faturado.historicoDevolucao.filter(h => h.ano == anoAnterior);

        //mes a mes
        let dataset1 = [];
        let dataset2 = [];
        //acumulado
        let dataset3 = [];
        let dataset4 = [];

        let accbefore = 0;
        let accafter = 0;
        if(dadoh1.length > 0  || dadoh2.length > 0){
          for (let i = 1; i <= 12; i++) {
              
              let m = dadoh1.filter(d => d.mes == i);
              let dev = devolvidoh1.filter(d => d.mes == i);

              if(m.length > 0){
                  let calc = m.reduce((a,x) => a + x.pesliq,0) - dev.reduce((a,x) => a + x.devolvido,0);

                  dataset1.push(calc);
                  accafter += calc;
              }else{
                  dataset1.push(0);
              }
              dataset3.push(accafter);
  
              let k = dadoh2.filter(d => d.mes == i);
              let devk = devolvidoh2.filter(d => d.mes == i);
              if(k.length > 0){
                  let calc = k.reduce((a,x) => a + x.pesliq,0) - devk.reduce((a,x) => a + x.devolvido,0);
                  dataset2.push(calc);
                  accbefore += calc;
              }else{
                  dataset2.push(0);
              }
              dataset4.push(accbefore);
          }
        
        }

        this.chartHistory.labels = this.meses;
        this.chartHistory.datasets = [
            {
                backgroundColor: "rgba(1, 116, 188, 0.50)",
                borderColor: "rgba(1, 116, 188, 0.50)",
                label: new Date().getFullYear(),
                data: dataset1,
                fill: true,
                tension: 0.3
            },
            {
                backgroundColor: "rgba(188, 116, 1, 0.50)",
                borderColor: "rgba(188, 116, 1, 0.50)",
                label: new Date().getFullYear()-1,
                data: dataset2,
                fill: true,
                tension: 0.3
            }
        ]
        this.chartHistoryAcumulado.labels = this.meses;
        this.chartHistoryAcumulado.datasets = [
            {
                backgroundColor: "rgba(1, 116, 188, 0.50)",
                borderColor: "rgba(1, 116, 188, 0.50)",
                label: new Date().getFullYear(),
                data: dataset3,
                fill: true,
                tension: 0.3
            },
            {
                backgroundColor: "rgba(188, 116, 1, 0.50)",
                borderColor: "rgba(188, 116, 1, 0.50)",
                label: new Date().getFullYear()-1,
                data: dataset4,
                fill: true,
                tension: 0.3
            }
        ]

        this.loadingCards = false;
        
        // console.table(this.usuario)
        
        if(this.usuario.usuarioAtualizado == false || !this.usuario.usuarioAtualizado){
          this.detalhes.atualizarCadastroRepresentante = true;
        }

      })
      .catch(err =>{
        console.log(err);
        alert('Não foi possível buscar faturado');
      })
    },

    obterVendido(){
      this.$axios.get('/relatorios/vendido',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp =>{
        this.vendidoMes = resp.data;
      })
      .catch(err =>{
        console.log(err);
        alert('Não foi possível buscar relatório de vendidos')
      })
    },

    obterPedidosPendentes(){
      this.$axios.get('/relatorios/pedidos-pendentes',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp =>{
        this.pedidosPendentes = resp.data;
        this.pedidosPendentes.sort((a,b) => b.pesliq - a.pesliq);
      })
      .catch(err =>{
        console.log(err);
        if(err.response.status == '401'){
          this.logout();
        }else{
          alert('Não foi possível buscar relatório')
        }
      })
      this.$axios.get('/relatorios/pedidos-pendentes-mes',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp =>{
        this.pedidosPendentesMes = resp.data;
        this.pedidosPendentesMes.sort((a,b) => b.pesliq - a.pesliq);
      })
      .catch(err =>{
        if(err.response.status == '401'){
          this.logout();
        }else{
          alert('Não foi possível buscar relatório')
        }
      })
    },

    obterPrevisto(){
      this.$axios.get('/relatorios/previsto',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp =>{
        this.previsto = resp.data[0];
        this.busy = false;
      })
      .catch(err =>{
        if(err.response.status == '401'){
          this.logout();
        }else{
          alert('Não foi possível buscar relatório')
        }
      })
    },

    obterRelatoriosSac(){
      this.$axios.get('/relatorios/SAC',{headers:{'Authorization':`Bearer ${this.token}`}})
      .then(resp => {
        this.relatoriosSAC = resp.data;
      })
      .catch(err => {
        if(err.response.status == '401'){
          this.logout();
        }else{
          alert('Não foi possível buscar relatório')
        }
      })
    },

    fecharAtualizacaoRepresentante(){
      this.detalhes.atualizarCadastroRepresentante = false;
    },

    logout(){
        alert('sessão expirada');
        sessionStorage.clear();
        this.$router.push('/login');
        this.$router.go();
    }
  }
}


</script>

<style>

</style>